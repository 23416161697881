import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title></Title>
                <Text>
                    <br />
                    <br />
                    Us presentem un curs únic i REVOLUCIONARI.
                    <br />
                    <br />
                    Un curs que combina totes les noticies d' actualitat setmanals i la cultura
                    general
                    <br />
                    <br />
                    En el moment de matricular-vos al curs tindràs accés al mes anterior de noticies
                    i els 3 posteriors a la matriculació.
                    <br />
                    <br />
                    Un curs creat per totes les convocatòries que venen a partir de gener de{" "}
                    {new Date().getFullYear()}
                    <br />
                    <br />
                    Sabem que la CULTURA GENERAL és infinita i complicada de preparar.
                    <br />
                    <br />
                    I també sabem que recopilar dia a dia les notícies d'actualitat és molt
                    complicat.
                    <br />
                    <br />
                    En aquest curs, us presentem infinitat de temes diferents de cultura general i
                    us ensenyem com aplicar diverses estratègies de memòria per recordar noms, dates
                    i dades d'interès que us poden aparèixer en els vostres exàmens de cultura
                    general.
                    <br />
                    <br />
                    Cada tema de cultura general compta amb un vídeo d'uns 20 minuts i s'acompanya
                    d'un test, i a més a més trobareu també simulacres d'examen a la plataforma
                    online.
                    <br />
                    <br />
                    En total, estem parlant de més de 5 hores de vídeos explicatius de qüestions de
                    cultura general: des de matemàtiques fins a geografia d'arreu del món, amb un
                    repàs de tots els continents i els països més importants, i també literatura i
                    ciència.
                    <br />
                    <br />
                    També trobareu TOTA l'actualitat des del mes de maig fins a enguany.
                    <br />
                    <br />
                    Cada setmana anirem actualitzant totes les notícies destacades i us pujarem
                    infinitat de test per posar-vos a prova.
                    <br />
                    <br />
                    I per rematar un vídeo explicatiu de 20 minuts de totes les notícies.
                    <br />
                    <br />
                    És un molt bon inici per afrontar amb garanties els vostres exàmens de cultura i
                    actualitat, així que, com sempre, només em queda recomanar-vos el curs.
                    <br />
                    <br />
                    Per un pagament de 34,99 €, tindreu accés a TOTS aquests continguts des del
                    moment que et matricules.
                    <br />
                    <br />
                    Apunta't ara!
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
